import React from 'react';
import Scrollspy from 'react-scrollspy';

import Tab from './Tab';
import TabLink from './TabLink';

const Sidebar = () => {
  const [tabs] = React.useState([
    { content: 'Home', href: 'intro' },
    { content: 'What we do', href: 'one' },
    // { content: 'Get in touch', href: 'two' },
    { content: 'Tours', href: '/tours' },
    { content: 'Crash Pads', href: '/crash-pad-rentals/' },
    { content: 'Contact', href: '/contact' },
    // { content: 'Privacy', href: '/privacy' },
    // { content: 'Terms', href: '/terms' },
  ]);

  return (
    <section id="sidebar">
      <div className="inner">
        <nav>
          <Scrollspy
            items={tabs.map(s => s.href)}
            currentClassName="active"
            offset={-300}
          >
            {tabs.filter(tab => !(/^\//.test(tab.href))).map(tab => (
              <Tab key={tab.href} {...tab} />
            ))}
          </Scrollspy>
          
          {tabs.filter(tab => /^\//.test(tab.href)).length && (
            <ul>
              {tabs.filter(tab => /^\//.test(tab.href)).map(tab => (
                <TabLink key={tab.href} {...tab} />
              ))}
            </ul>
          )}
        </nav>
      </div>
    </section>
  );
}

export default Sidebar;
