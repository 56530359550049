import React from 'react';

import pic1 from '../../images/img_2155_sq2.jpg';
import pic2 from '../../images/guia_sq2.jpg';
import pic3 from '../../images/10_dez09_11_sq2.jpg';
import pic4 from '../../images/img_2031_sq2.jpg';
import pic5 from '../../images/pic05.jpg';

/*

import pic1 from '../../images/espinhaco3_mar10_72.jpg';
import pic2 from '../../images/img_2155.jpg';
import pic3 from '../../images/img_2031.jpg';
import pic4 from '../../images/guia.jpg';
import pic5 from '../../images/sintra10_dez09_11.jpg';

/static/photos/Espinhaco3_Mar10_72.jpg
/static/photos/IMG_2155.JPG
/static/photos/IMG_2031.jpg
/static/photos/guia.jpg
/static/photos/Sintra10_Dez09_11.jpg
 */

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: '/tours',
      image: pic1,
      heading: 'Learn to Climb',
      description: 'You’ve never climbed or you want to improve your safety and climbing techniques, we have courses for you!',
      to: {
        href: '/tours',
        label: 'Learn more',
      },
    },
    {
      href: '/tours',
      image: pic2,
      heading: 'Rock-Climbing and Abseiling',
      description: 'Climbing and abseiling experience in fantastic places in Sintra.',
      to: {
        href: '/tours',
        label: 'Learn more',
      },
    },
    {
      href: '/tours',
      image: pic3,
      heading: 'Crack Climbing',
      description: 'You can find some of the best crack climbing in Sintra.',
      to: {
        href: '/tours',
        label: 'Learn more',
      },
    },
    {
      href: '/crash-pad-rentals',
      image: pic4,
      heading: 'Crash Pad Rentals',
      description: 'Need some crash pads for your climbing activities?',
      to: {
        href: '/crash-pad-rentals',
        label: 'Learn more',
      },
    },
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 spotlights">
       {features.map(feature => <Feature key={feature.heading} {...feature} />)}
    </section>
  );
}

export default Features;
