import React from 'react';

import Scroll from '../common/Scroll';
import bgPic from '../../images/espinhaco3_mar10_72.jpg';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    {/*<img src={bgPic} alt="" data-position="center center" />*/}
    <div className="inner">
      <h1>Sintra Climbing Tours</h1>
      <p>
        Rock Climbing Tours and Lessons, Abseiling, Bouldering.
      </p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="button">
              Learn more
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;

// /static/photos/Espinhaco3_Mar10_72.jpg
// /static/photos/IMG_2155.JPG
// /static/photos/IMG_2031.JPG
// /static/photos/guia.jpg
// /static/photos/Sintra10_Dez09_11.jpg